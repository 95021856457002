body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.BlueFixed
{
	font-family: "Courier New", Courier, mono;
	font-size: 24px;
	font-weight: bold;
	color: #006699;
	text-align: center;
}
.Black10
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #666666;
	text-align: center;
}
.Black10Name
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #000000;
	text-align: center;
}
